import React from 'react';
import logo from '../../assets/images/logo.png';
import {Footer} from "../../components/Footer";

export const Impressum = () => {
  return (
    <>
      <div className="container">
        <img src={logo} className="logo" alt="IQEST"/>
        <h1 className="heading">Impressum</h1>
        <div>
          <h4>Inhaber, Herausgeber und Anbieter des iQest® Shops</h4>
          <p>nutri4you GmbH</p>
        </div>
        <div>
          <h4>Unternehmensgegenstand</h4>
          <p>Handel mit Nahrungsergänzungsmitteln, Naturprodukten und natürlichen Körperpflegeprodukten</p>
        </div>
        <div>
          <h4>Geschäftsführer und Verantwortlicher für den Inhalt der Webseite</h4>
          <p>Reinhard Wallner </p>
        </div>
        <div>
          <h4>Firmensitz</h4>
          <p>Via Sanitas 1, A-5082 Grödig</p>
          <p>Telefon +43 (0) 6246 211 44</p>
          <p> Ansprechperson: <a href="mailto:service@iqdh.eu">service@iqdh.eu</a></p>
        </div>
        <div>
          <h4>Webseite</h4>
          <a href={"www.shop.iqest.com"}>www.shop.iqest.com</a>
        </div>
        <div>
          <h4>UID – Umsatzsteueridentifikationsnummer</h4>
          <p>ATU69486326</p>
        </div>
        <div>
          <h4>Firmenbuchnummer</h4>
          <p>FN431633f, Landesgericht Salzburg</p>
        </div>
        <div>
          <h4>Gewerberechtliche Aufsichtsbehörde</h4>
          <p>Magistrat der Stadt Salzburg als Gewerbebehörde</p>
        </div>
        <div>
          <h4>Gewerberechtliche Vorschriften</h4>
          <p>Gewerbeordnung – GewO 1994</p>
        </div>
        <div>
          <h4>Beschwerden</h4>
          <p>Beschwerden richten Sie bitte an die oben genannte E-Mail-Adresse oder an die
            Online-Streitbeilegungsplattform der EU für Verbraucher: http://ec.europa.eu/odr</p>
          <p><a href="https://shop.iqest.com/allgemeine-geschaeftsbedingungen">Allgemeine Geschäftsbedingungen</a></p>
          <p><a href="https://shop.iqest.com/datenschutz">Datenschutzbestimmungen</a></p>
        </div>
        <div>
          <h4>Copyright und geschützte Bezeichnungen</h4>
          <p>Der Inhalt dieser Webseite ist urheberrechtlich geschützt. Jede kommerzielle oder nicht-kommerzielle
            Verwertung, Vervielfältigung, Verbreitung oder Zurverfügungstellung von Marken bzw. Inhalten dieser Webseite
            – auch nur auszugsweise – bedarf der vorherigen schriftlichen Zustimmung von Nutri4You bzw. der jeweiligen
            Urheber der betroffenen Inhalte sowie der Inhaber der betroffenen Marken.</p>
          <p>Diese Website enthält zudem geschützte Bezeichnungen (wie Warenzeichen, Marken, Geschäftsbezeichnungen und
            Firmennamen). Zu den geschützten Marken zählt insbesondere die Marke IQEST®. Die Verwendung dieser
            geschützten Bezeichnungen bedarf ebenfalls der vorherigen Zustimmung der Inhaber der jeweiligen
            Schutzrechte.</p>
        </div>
        <div>
          <h4>Wichtiger Hinweis</h4>
          <p>Die Informationen auf dieser Webseite dürfen keinesfalls so interpretiert werden, dass bestimmte Nährstoffe
            bzw. Produkte von Nutri4You eine abwechslungsreiche Ernährung ersetzen bzw. Eigenschaften zugeschrieben
            werden, die über die zulässigen Angaben gemäß Health Claim-Verordnung, VO (EG) NR. 1924/2006,
            hinausgehen.</p>
          <p>Diese allgemeinen Informationen stellen weder eine individuelle Diagnose noch eine medizinische
            Therapieempfehlung dar. Sie ersetzen weder eine individuelle medizinische Beratung noch eine individuelle
            Lebens- bzw. Ernährungsberatung. Bei individuellen ernährungsphysiologischen oder gesundheitlichen Fragen
            wende dich bitte an Ärzte bzw. oder entsprechend qualifizierte Berater deines Vertrauens.</p>
        </div>
        <div>
          <h4>Haftungsausschluss für fremde Inhalte und Links</h4>
          <p>Die Webseite enthält fremde Inhalte (wie User-Kommentare und Links zu fremden Webseiten). Diese Inhalte und
            Links werden mit der gebotenen Sorgfalt regelmäßig überprüft. Hierbei werden Beanstandungen betreffend die
            Rechtmäßigkeit der fremden Inhalte sowie zur Fehlerhaftigkeit von Links berücksichtigt. Wir übernehmen aber
            keine Haftung für die Richtigkeit und Rechtmäßigkeit der fremden Inhalte und Links. Die Nutzung dieser
            fremden Inhalte sowie der fremden Links liegt ausschließlich in deiner eigenen Verantwortung.</p>
        </div>
      </div>
      <Footer/>
    </>
  )
};