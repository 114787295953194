import React from 'react';
import logo from '../../assets/images/logo.png';
import {Footer} from "../../components/Footer";

export const Cookies = () => {
  return (
    <>
      <div className="container">
        <img src={logo} className="logo" alt="IQEST"/>
        <h1 className="heading">Session-Cookies</h1>
        <div>
          <p>Auf der Webseite werden Session-Cookies zur Terminal-Auswahl verwendet.
            Diese werden automatisch gelöscht, wenn Sie die Sitzung beenden, die Webseite verlassen oder das
            Browser-Fenster schließen.</p>
        </div>
      </div>
      <Footer/>
    </>
  )
};