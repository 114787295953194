import './App.css';
import ActivateAccount from "./pages/ActivateAccount";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {DataProtection} from "./pages/DataProtection";
import {Cookies} from "./pages/Cookies";
import {Home} from "./pages/Home";
import {Impressum} from "./pages/Impressum";
import history from "./utils/history";
import "./assets/styles/styles.scss";

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Route path="/data-protection" exact component={DataProtection} />
        <Route path="/impressum" exact component={Impressum} />
        <Route path="/cookies-policy" exact component={Cookies} />
        <Route path="/confirm-email" exact component={ActivateAccount} />
        <Route path="/reset-password" exact component={ActivateAccount} />
        <Route path="/" exact component={Home} />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
