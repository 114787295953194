import React from 'react';
import logo from '../../assets/images/logo.png';
import {Footer} from "../../components/Footer";

export const DataProtection = () => {
  return (
    <>
      <div className="container">
        <img src={logo} className="logo" alt="IQEST"/>
        <h1 className="heading">Datenschutz</h1>
        <p><strong>Datenverarbeitung gemäß Art. 13 und 14 DSGVO</strong> (Datenschutz Grundverordnung)</p>
        <div>
          <h4>1. Wer ist für die Datenverarbeitung verantwortlich?</h4>
          <p>IQ Digital Health GmbH</p>
          <p>Via Sanitas 1, A-5082 Grödig</p>
          <p> Ansprechperson: <a href="mailto:service@iqdh.eu">service@iqdh.eu</a></p>
          <p><a href={"www.iqdh.eu"}>www.iqdh.eu</a></p>
        </div>

        <div>
          <h4>2. Welche Daten werden verarbeitet?</h4>
          <p>Wir verarbeiten ausschließlich folgende personenbezogenen Kundendaten, die wir im Rahmen der Kundenbeziehung von Ihnen direkt erhalten:</p>
          <ul>
            <li>Name</li>
            <li>Kontaktdaten (wie Adresse, Telefon oder E-Mail)</li>
            <li>Produktdaten (wie z.N.: Produktname, Artikel Nummer und Menge)</li>
            <li>Kostenfreies Newsletter-Abonnement mit Kundeninformationen, jederzeit
              widerrufbar</li>
            <li>Sonstige geschäftsrelevante Daten (wie Sonderwünsche und Korrespondenz)</li>
          </ul>
        </div>

        <div>
          <h4>3. Für welche Zwecke werden die Daten auf welcher Rechtsgrundlage verarbeitet?</h4>
          <p>Die Datenverarbeitung ist streng vertraulich. Sie erfolgt ausschließlich zum Zweck des persönlichen Kundenservices auf Grundlage unserer vertraglichen Kundenbeziehung (einschließlich Auftragsabwicklung, Lieferservice und Kundenberatung) bzw. im Rahmen Ihrer Einwilligung gemäß Art. 6 Abs. 1 DSGVO. Die Verarbeitung oder Weitergabe der Daten an Dritte zu vertragsfremden Zwecken ist hingegen ausgeschlossen.</p>
        </div>

        <div>
          <h4>4. Wie lange werden die Daten gespeichert?</h4>
          <p>Wir speichern Kundendaten für die Dauer unserer Kundenbeziehung nach Maßgabe der vertraglichen und gesetzlichen Aufbewahrungs- und Dokumentationsobliegenheiten.</p>
        </div>

        <div>
          <h4>5. Welche Rechte haben Sie?</h4>
          <ul>
            <li>Auskunftsrecht zur Datenverarbeitung (Transparenz)</li>
            <li>Recht auf Berichtigung Ihrer Kundendaten (Datenrichtigkeit)</li>
            <li>Recht auf Einschränkung und Löschung Ihrer Kundendaten (Datenminimierung)</li>
            <li>Widerspruchsrecht gegen die Datenverarbeitung</li>
            <li>Beschwerderecht an die Österreichische Datenschutzbehörde (www.dsb.gv.at)</li>
          </ul>
        </div>

        <div>
          <h4>6. Wie werden die Daten geschützt?</h4>
          <ul>
            <li>Auskunftsrecht zur Datenverarbeitung (Transparenz)</li>
            <li>Recht auf Berichtigung Ihrer Kundendaten (Datenrichtigkeit)</li>
            <li>Recht auf Einschränkung und Löschung Ihrer Kundendaten (Datenminimierung)</li>
            <li>Widerspruchsrecht gegen die Datenverarbeitung</li>
            <li>Beschwerderecht an die Österreichische Datenschutzbehörde (www.dsb.gv.at)</li>
          </ul>
        </div>
        <div>
          <h4>7. Ihr Recht auf Widerruf</h4>
          <p>Selbstverständlich können Sie der Nutzung Ihrer personenbezogenen Daten für Werbezwecke jederzeit widersprechen. Bitte teilen Sie dies unserem Kundenservice schriftlich unter <a href="mailto:service@iqdh.eu">service@iqdh.eu mit.</a></p>
          <p>Wichtiger Hinweis: Mit dem Widerruf werden alle Laufzeitdaten, welche Sie in der iQest App erfasst haben, unzugänglich gemacht und können auch nicht wiederhergestellt werden.</p>
        </div>
        <div>
          <h4>Datenschutz im Online Shop</h4>
          <p>
            <strong>Schutz der Privatsphäre.</strong> Der Schutz der Privatsphäre ist für uns von größter Wichtigkeit, deshalb ist es für uns selbstverständlich, die gesetzlichen Datenschutzbestimmungen einzuhalten und Ihre persönlichen Daten vertraulich zu behandeln.
          </p>
          <p>
            Im Folgenden erfahren Sie, welche Ihrer Daten wir wann speichern und aus welchem Grund. Wenn Sie außerdem noch den folgenden Punkt beachten, können Sie persönlich dazu beitragen, die Sicherheit Ihrer Daten zu steigern: Halten Sie Ihr Login und vor allem natürlich Ihr Passwort geheim
          </p>
          <p>Bei der Wiederherstellung eines eventuell vergessenen Passwortes wird das neue Passwort nicht auf dem Screen angezeigt, sondern wird Ihnen persönlich per E-Mail zugestellt, sofern Sie uns Ihre Mailadresse mitgeteilt haben. Zu Ihrer Sicherheit können wir Ihr altes Passwort nicht anzeigen. Sie können jedoch das von uns vergebene Passwort unter „Kennwort ändern“ wieder auf eines Ihrer Wahl ändern.</p>
          <p>Welche Daten wir speichern und warum. Wenn Sie etwas bei uns bestellen, benötigen wir einige persönliche Grundinformationen von Ihnen, wie Ihren Namen, Ihre vollständige Anschrift und Ihre Telefonnummer, damit wir Ihnen Ihre Ware schnell und reibungslos ausliefern können und Sie gegebenenfalls bei Rückfragen erreichen können. Diese Daten können zur Auftragsabwicklung an das Nutri4You Info-Center, an Speditionen zur Versandabwicklung und zur Bonitätsprüfung an Dienstleistungsunternehmen weitergegeben werden. Eine kommerzielle Nutzung der Daten findet nicht statt (kein Adressverkauf).</p>
          <p>Zu Ihrem persönlichen Komfort können Sie bei einer Folgebestellung mit Ihrem Benutzernamen und Kennwort in den Online-Shop einsteigen, so dass eine erneute Dateneingabe entfällt.</p>
          <p>
            Ihr Recht auf Widerruf. Selbstverständlich können Sie der Nutzung Ihrer personenbezogenen Daten für Werbezwecke jederzeit widersprechen. Bitte teilen Sie dies unserem Kundenservice schriftlich unter <a href="mailto:service@iqdh.eu">service@iqdh.eu mit.</a> mit.</p>
        </div>
        <div>
          <h4>NEWSLETTER</h4>
          <p>Um den auf unserer Webseite angebotenen Newsletter zu beziehen, können Sie sich über unser Formular anmelden. Dabei nutzen wir das sogenannte Double-Opt-In Verfahren. Hier wird zunächst eine Bestätigungsmail an Ihre angegebene E-Mail Adresse gesendet, mit der Bitte um Bestätigung. Die Anmeldung wird erst wirksam, wenn Sie den in der Bestätigungsmail enthaltenen Aktivierungslink anklicken. Wir verwenden Ihre an uns übertragenen Daten ausschließlich für den Versand des Newsletters, der Informationen oder Angebot enthalten kann.</p>
          <p>Wir verwenden Active Campaign, um unseren Newsletter zu versenden. Ihre Daten werden daher an die ActiveCampaign, LLC. übermittelt. Dabei ist es der ActiveCampaign, LLC. untersagt, Ihre Daten für andere Zwecke als für den Versand des Newsletters zu nutzen. Eine Weitergabe oder ein Verkauf Ihrer Daten ist der ActiveCampaign, LLC. nicht gestattet. Active Campaign ist ein amerikanischer Software Anbieter, welcher nach den Anforderungen der DSGVO und des BDSG sorgfältig ausgewählt wurde.</p>
          <p>Sie können Ihre Einwilligung zur Speicherung der Daten und deren Nutzung zum Newsletter- Versand jederzeit widerrufen, z.B. über den Abmelde-Link im Newsletter.</p>
        </div>
      </div>
      <Footer />
    </>
  )
};