import React, { Component } from 'react';
import api from 'utils/api';
import getParameterByName from 'utils/queryString';
import './styles.scss';
import logo from 'images/logo.png';
import { toast, Slide } from 'react-toastify';
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer";

class ActivateAccount extends Component {
  state = {
    loading: false,
    error: false,
    reseted: false,
  }

  componentDidMount() {
    if (window.location.pathname.includes('confirm')) {
      this.activateUserAccount();
    }
  }

  activateUserAccount = async () => {
    const id = getParameterByName('code');
    const tenant = getParameterByName('tenant');
    this.setState({
      loading: true,
    });
    const { response, error } = await api.activateUserAccount(id, tenant);

    if (!response) {
      return this.setState({
        title: "Fehler",
        text: "Etwas schief gelaufen. Versuchen Sie es bitte erneut.",
        loading: false,
      })
    };

    if (response.status === "already_confirmed") {
      this.setState({
        title: "Hinweis",
        text: "Die e-Mail wurde bereits aktiviert"
      })
    } else if (response.status === "account_disabled") {
      this.setState({
        title: "Hinweis",
        text: "Diese e-Mail wurde nicht innerhalb einer Woche aktiviert und deshalb gesperrt"
      })
    } else if (response.status === "ok") {
      this.setState({
        title: "Erfolg",
        text: "Die e-Mail wurde erfolgleich aktiviert"
      })
    }

    this.setState({
      loading: false,
      error: !!error
    });
  }

  resetUserPassword = async () => {
    const id = getParameterByName('code');
    const tenant = getParameterByName('tenant');
    if (this.state.password !== this.state.passwordConfirm) {
      return this.showMessage('Die Passwörter sind nicht identisch.')
    }

    const data = {
      id,
      tenant,
      payload: {
        password: this.state.password,
        passwordConfirm: this.state.passwordConfirm,
      }
    };

    const { error } = await api.resetPassword(data);

    this.setState({
      loading: false,
      error: !!error,
      reseted: true
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showMessage = (message, type = "error") =>
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type,
    });

  render() {
    const { loading, error, reseted } = this.state;
    return (
      <>
        <div className="h-100 bg-animation bg-not-found activate-page">
          <img src={logo} alt="iQest" className="logo" />
          <div className="modal">
            <div className="modal-content">
              <div className="modal-body">
                <div className="h5 modal-title text-center">
                  {window.location.pathname.includes('confirm') && (
                    <h4 className="mt-2">
                      <div className="mb-2">{
                        loading
                          ? 'Activating account...'
                          : this.state.title
                      }</div>
                      <span>
                                          {
                                            loading
                                              ? 'Bitte warten'
                                              : this.state.text
                                          }
                                          </span>
                    </h4>
                  )}
                  {window.location.pathname.includes('reset') && (
                    <>
                      {reseted && (
                        <h4 className="mt-2">
                          <div className="mb-2">{
                            error
                              ? 'Fehler'
                              : 'Erfolg'
                          }
                          </div>
                          <span>
                                          {
                                            loading
                                              ? 'Bitte warten'
                                              : (
                                                error
                                                  ? 'Der Link ist nicht aktiv.'
                                                  : 'Passwort wurde erfolgreich zurückgesetzt!'
                                              )
                                          }
                                          </span>
                        </h4>
                      )}
                      {!reseted && (
                        <div className="mt-2">
                          <p className="mb-2">Passwort zurücksetzen</p>
                          <input
                            type="password"
                            name="password"
                            className="input"
                            placeholder="Passwort"
                            onChange={this.handleChange} />
                          <input
                            type="password"
                            name="passwordConfirm"
                            className="input"
                            placeholder="Passwort bestätigen"
                            onChange={this.handleChange} />
                          <button className="button" onClick={this.resetUserPassword}>Speichern</button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            Copyright 2021 IQ Digital Health GmbH
          </div>
          {/*<div className="footer">*/}
          {/*  <div className="app-footer-left">*/}
          {/*    <a*/}
          {/*      rel="noopener noreferrer"*/}
          {/*      target="_blank"*/}
          {/*      href="https://iqest.com/data-protection"*/}
          {/*    >*/}
          {/*      Datenschutz |{" "}*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      rel="noopener noreferrer"*/}
          {/*      target="_blank"*/}
          {/*      href="https://iqest.com/impressum"*/}
          {/*    >*/}
          {/*      Impressum |{" "}*/}
          {/*    </a>{" "}*/}
          {/*    <a*/}
          {/*      rel="noopener noreferrer"*/}
          {/*      target="_blank"*/}
          {/*      href="https://iqest.com/cookies-policy"*/}
          {/*    >*/}
          {/*      Verwendung von Cookies*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <Footer />
        </div>
      </>
    )
  }
}

export default ActivateAccount;
