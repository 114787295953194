import React from 'react';
import {Link} from "react-router-dom";

export const Footer = () => {
  return (
    <div className="footer">
      <Link to="/data-protection">Datenschutz</Link>
      <Link to="/cookies-policy">Verwendung von Cookies</Link>
      <Link to="/impressum">Impressum</Link>
    </div>
  )
};