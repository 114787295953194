import axios from 'axios';
import { get } from 'lodash';
import { userApiVersion, userServiceUrl } from 'config/environment';

const getFlowId = () => {
  return Math.random().toString().substr(2);
}

const activateUserAccount = (id, tenant) => {
  return axios({
    url: `${ userServiceUrl }/api/users/confirm-email-address/v1.0/?confirmationCode=${ id }&tenant=${tenant}`,
    method: 'put',
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({response: response.data}))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to activate user account"
      }
    }))
}
const resetPassword = (data) => {
  return axios({
    url: `${ userServiceUrl }/api/users/reset-forgotten-password/v1.0/?confirmationCode=${ data.id }&tenant=${data.tenant}`,
    method: 'put',
    data: data.payload,
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({response: response.data}))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {})
      }
    }))
}

export default  {
  activateUserAccount,
  resetPassword
}