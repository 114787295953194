import React from 'react';
import logo from '../../assets/images/logo.png';
import {Footer} from "../../components/Footer";

export const Home = () => {
  return (
    <>
      <div className="container home">
        <img src={logo} alt="IQEST" className="logo logo--main" />
      </div>
      <Footer />
    </>
  )
};